@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mooli&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
 body,html {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Mooli", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: seashell;
 }
 .speedometer {
  margin-left: auto;
  margin-right: auto;
 }
